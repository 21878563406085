import React from "react";
import useForm from "react-hook-form";
import { Card, Checkbox } from "antd";

import { createCustomer } from "../api";
import { countries } from "../api/countries";
import styles from "./NewCustomersPage.module.css";

export default function NewCustomersPage({ history }) {
  const { handleSubmit, register, errors } = useForm();
  const [loading, setLoading] = React.useState(false);
  const [customerFirebaseConfig, setCustomerFirebaeConfig] =
    React.useState(null);

  const [isChecked, setIsChecked] = React.useState({});

  // // get file actual value of input html
  const onFileChange = (e) => {
    e.persist();
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const obj = JSON.parse(e.target.result);
        setCustomerFirebaeConfig(obj);
      } catch (error) {
        setCustomerFirebaeConfig(undefined);
      }
    };
    if (e.target.files.length) {
      reader.readAsText(e.target.files[0]);
    } else {
      setCustomerFirebaeConfig(null);
    }
  };

  function submit(values) {
    setLoading(true);
    console.log("values new customer", values);
    const {
      customerFirebaseConfig: configJSONFile,
      android,
      ios,
      ...restValues
    } = values;

    if (configJSONFile && customerFirebaseConfig === undefined) {
      setLoading(false);
      alert("Invalid JSON file.");
      return;
    }

    if ((ios || android) && !customerFirebaseConfig) {
      setLoading(false);
      alert("Please specify firebase config file.");
      return;
    }

    const {
      appId,
      apiKey,
      projectId,
      authDomain,
      databaseURL,
      storageBucket,
      measurementId,
      messagingSenderId,
    } = customerFirebaseConfig ?? {};

    if (
      customerFirebaseConfig &&
      (!apiKey ||
        !authDomain ||
        !databaseURL ||
        !projectId ||
        !storageBucket ||
        !messagingSenderId ||
        !appId ||
        !measurementId)
    ) {
      setLoading(false);

      alert("INVALID FIREBASE CONFIG");
      return;
    }
    let allFirebaseConfig = customerFirebaseConfig
      ? { ...customerFirebaseConfig }
      : null;

    if (android) {
      const { apiKey, appId, clientId } = android;
      allFirebaseConfig.androidApiKey = apiKey;
      allFirebaseConfig.androidAppId = appId;
      allFirebaseConfig.androidClientId = clientId;
    }

    if (ios) {
      const { appId, apiKey, clientId } = ios;
      allFirebaseConfig.iosApiKey = apiKey;
      allFirebaseConfig.iosAppId = appId;
      allFirebaseConfig.iosClientId = clientId;
    }

    createCustomer({ ...restValues, customerFirebaseConfig: allFirebaseConfig })
      .then((res) => {
        if (!res.data.success) {
          alert("Error! " + res.data.data);
        } else {
          alert("success");
          history.goBack();
        }
        setLoading(false);
      })
      .catch((e) => console.log(e));
  }

  const onCheckboxChecked = (event) => {
    const { name, checked } = event.target;

    setIsChecked((prevValue) => ({
      ...prevValue,
      [name]: checked,
    }));
  };

  return (
    <div className={styles.page}>
      <h2 className={styles.title}>Add New Customer</h2>
      <form onSubmit={handleSubmit(submit)}>
        <label htmlFor="customer_name">Customer Company Name:</label>
        <input type="text" name="customer_name" ref={register} required />
        <label htmlFor="website">Website</label>
        <input type="text" name="website" ref={register} />
        <label htmlFor="subdomain">
          SubDomain Name
          <br />
          <span style={{ fontSize: "0.8em" }}>
            (This will be the url for the client : <br />
            https://________.sewa360.com)
          </span>
        </label>
        <input type="text" name="subdomain" required ref={register} />
        <label htmlFor="phone">Phone Number:</label>
        <input type="number" name="phone" ref={register} />
        <h4>Administrator Login</h4>
        <label htmlFor="admin_name">Administrator Name:</label>
        <input type="text" name="admin_name" required ref={register} />
        <label htmlFor="login_email">Email:</label>
        <input type="email" name="email" required ref={register} />
        <label htmlFor="type">Country:</label>
        <select
          type="select"
          name="country"
          ref={register({
            required: "Country is required",
          })}
          placeholder="Country"
          style={{ marginTop: 5, width: 245, fontSize: 16, height: 35 }}
        >
          <option selected disabled></option>
          {countries.sort().map((c, i) => {
            return (
              <option key={c} value={c.toLowerCase()}>
                {c}
              </option>
            );
          })}
        </select>
        <br />
        {errors.country && errors.country.message}
        <label htmlFor="type">Customer Type:</label>
        <select
          type="select"
          name="type"
          ref={register({
            required: "Customer type is required",
          })}
          placeholder="Customer Type"
          style={{ marginTop: 5, width: 245, fontSize: 16, height: 35 }}
        >
          <option selected disabled></option>
          <option value="Audit Firm Nepal">Audit Firm Nepal</option>
          <option value="Audit Firm India">Audit Firm India</option>
          <option value="Software Company">Software Company</option>
        </select>
        <br />
        {errors.type && errors.type.message}
        <br />
        <hr />
        <h4>Business Entity</h4>
        <label htmlFor="entity.name">Name:</label>
        <input type="text" name="entity.name" required ref={register} />
        <label htmlFor="entity.pan">Vat/Pan Number:</label>
        <input type="text" name="entity.pan" required ref={register} />
        <label>Firebase config</label>
        <input
          type="file"
          accept=".json"
          ref={register}
          onChange={onFileChange}
          name="customerFirebaseConfig"
        />
        <br />
        <div style={{ marginTop: 8 }}>
          <Checkbox onChange={onCheckboxChecked} name="android">
            Android
          </Checkbox>
          <Checkbox onChange={onCheckboxChecked} name="ios" key="ios">
            IOS
          </Checkbox>
          <div style={{ display: "flex", gap: 8, marginTop: 8 }}>
            {isChecked["android"] && (
              <Card title="Android">
                <label htmlFor="android.appId">App Id:</label>
                <input
                  type="text"
                  name="android.appId"
                  ref={register}
                  required
                />

                <label htmlFor="android.apiKey">Api Key:</label>
                <input
                  type="text"
                  name="android.apiKey"
                  ref={register}
                  required
                />
                <label htmlFor="android.clientId">Client Id:</label>
                <input
                  type="text"
                  name="android.clientId"
                  ref={register}
                  required
                />
              </Card>
            )}
            {isChecked["ios"] && (
              <Card title="IOS">
                <label htmlFor="ios.appId">App Id:</label>
                <input type="text" name="ios.appId" ref={register} required />
                <label htmlFor="ios.apiKey">Api Key:</label>
                <input type="text" name="ios.apiKey" ref={register} required />
                <label htmlFor="ios.clientId">Client Id:</label>
                <input
                  type="text"
                  name="ios.clientId"
                  ref={register}
                  required
                />
              </Card>
            )}
          </div>
        </div>

        <label htmlFor="entity.email">Email address:</label>
        <input type="email" name="entity.email" required ref={register} />
        <label htmlFor="entity.phone_no">Phone No.:</label>
        <input
          type="number"
          name="entity.phone_no"
          min={1}
          style={{ width: 150 }}
          required
          ref={register}
        />
        <label htmlFor="entity.address">Address:</label>
        <input type="text" name="entity.address" required ref={register} />
        {loading ? (
          <p>Loading...</p>
        ) : (
          <input type="submit" value="Create Customer" />
        )}
      </form>
    </div>
  );
}
