import React, { useEffect, useState } from "react";
import { Route, Switch, Redirect, useHistory } from "react-router-dom";

import { auth } from "./api";
import LoginPage from "./pages/LoginPage";
import CustomersPage from "./pages/CustomersPage";
import NewCustomersPage from "./pages/NewCustomerPage";
import "antd/dist/antd.css";

function useAuth() {
  const [loggedIn, setLoggedIn] = useState(null);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        auth.currentUser.getIdTokenResult().then((user) => {
          if (!user.claims.super_admin) {
            auth.signOut();
            alert("You are not authorized to login here");
          }
          setLoggedIn(true);
        });
      } else if (!user) {
        setLoggedIn(false);
      }
    });
  }, []);
  return loggedIn;
}

function App() {
  const history = useHistory();

  const loggedIn = useAuth();

  if (loggedIn === null)
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h1
          style={{
            fontWeight: "400",
          }}
        >
          Loading...
        </h1>
      </div>
    );

  if (!loggedIn)
    return (
      <Switch>
        <Route path="/login" component={LoginPage} />
        <Redirect to="/login" />
      </Switch>
    );

  return (
    <div className="App">
      <div className="toolbar">
        <span onClick={() => history.push("/")} className="title">
          Super Admin
        </span>
        <button className="logout-button" onClick={() => auth.signOut()}>
          Logout
        </button>
      </div>
      <Switch>
        <Route
          path="/"
          exact
          component={() => <CustomersPage history={history} />}
        />
        <Route path="/new-customer" component={NewCustomersPage} />
        <Redirect to="/" />
      </Switch>
    </div>
  );
}

export default App;
