import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/database";
import "firebase/storage";

import config from "./config";
import { message } from "antd";

const app = firebase.initializeApp(config);

export default app;
const storageRef = app.storage().ref();
export const auth = app.auth();
export const firestore = app.firestore();
export const createCustomer = app
  .functions()
  .httpsCallable("customer-createCustomer");

export const resendOnboardingMail = app
  .functions()
  .httpsCallable("resendOnboardingMail");

export async function getCustomers() {
  let customers = (await firestore.collection("customers").get()).docs.map(
    (i) => i.data()
  );
  return customers;
}

export async function getCustomerPaymentStatus() {
  const customerRef = firestore.collection("customers");
  const getActiveUsersLength = async (customerKey) =>
    (
      await customerRef
        .doc(customerKey)
        .collection("users")
        .where("blocked", "==", "false")
        .get()
    ).docs.length;

  let customersDetail = {};
  const customers = await customerRef.get();
  for (const customer of customers.docs) {
    let activeUsers = await getActiveUsersLength(customer.id);
    activeUsers = activeUsers > 10 ? activeUsers - 10 : 0;
    const expectedBillAmount = 5000 + activeUsers * 500;
    customersDetail[customer.id] = { activeUsers, expectedBillAmount };
  }
  return customersDetail;
}

export async function getFeedbacks() {
  let feedbacks = (
    await firestore.collection("feedbacks").orderBy("created_at", "desc").get()
  ).docs.map((i) => i.data());
  return feedbacks;
}
export async function deleteFeedback(key) {
  await firestore.collection("feedbacks").doc(key).delete();
}

export async function resolveFeedback(key) {
  const resolve = window.confirm("Are you sure, feedback is resolved?");

  if (!resolve) {
    alert("cancelled");
    return;
  }
  alert("feedback resolved");
  await firestore.collection("feedbacks").doc(key).update({
    resolved: true,
  });
}

export async function getBills(customerKey, cb) {
  firestore
    .collection("customers")
    .doc(customerKey)
    .collection("billingInvoices")
    .orderBy("createdAt", "desc")
    .onSnapshot((d) => cb(d.docs.map((i) => i.data())));
}

export async function markAsPaid(data) {
  await verifyPayment(data);
}

export async function setStrgBill(
  data,
  customerKey,
  invoiceKey,
  setBillLoading
) {
  console.log({ invoiceKey });
  console.log({ customerKey });
  const ext = data[0].name.split(".")[1];
  const snap = await storageRef
    .child(`${customerKey}/bills/${invoiceKey}.${ext}`)
    .put(data[0]);
  message.success("File uploaded");
  setBillLoading(false);
  const url = await snap.ref.getDownloadURL();
  const ref = firestore
    .collection("customers")
    .doc(customerKey)
    .collection("billingInvoices")
    .doc(invoiceKey);
  return await ref.update({ imageUrl: url });
}
export async function uploadVatBill(
  data,
  customerKey,
  invoiceKey,
  setBillLoading
) {
  const ext = data[0].name.split(".")[1];
  const snap = await storageRef
    .child(`${customerKey}/vatBills/${invoiceKey}.${ext}`)
    .put(data[0]);
  message.success("Bill uploaded");
  setBillLoading(false);
  const url = await snap.ref.getDownloadURL();
  const ref = firestore
    .collection("customers")
    .doc(customerKey)
    .collection("billingInvoices")
    .doc(invoiceKey);
  return await ref.update({ billUrl: url });
}

export async function extendDueDate(customerKey, dueDate) {
  await firestore.collection("customers").doc(customerKey).update({
    dueDate,
    active: true,
  });
}

export const verifyPayment = app
  .functions()
  .httpsCallable("billing-verifyKhaltiPayment");

export async function getWorklogDateRange(
  customerKey,
  cur_date,
  last_thirty_date
) {
  const worklogs = await firestore
    .collection("customers")
    .doc(customerKey)
    .collection("worklogs")
    .where("date", "<=", cur_date)
    .where("date", ">=", last_thirty_date)
    .get();
  return worklogs.docs.map((d) => d.data());
}

export async function getUserDateRange(
  customerKey,
  cur_date,
  last_thirty_date
) {
  const response = await firestore
    .collection("customers")
    .doc(customerKey)
    .collection("users")
    .where("createdAt", "<=", cur_date)
    .where("createdAt", ">=", last_thirty_date)
    .get();
  return response.docs.map((doc) => doc.data());
}
