import { EyeOutlined } from "@ant-design/icons";
import { Button, Empty, Form, Input, message, Modal, Select } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
  extendDueDate,
  getBills,
  getCustomers,
  getFeedbacks,
  getUserDateRange,
  getWorklogDateRange,
  markAsPaid,
  resendOnboardingMail,
  resolveFeedback,
  setStrgBill,
  uploadVatBill,
} from "../api/index";

export default function CustomersPage({ history }) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [datesOfTheMonth, setDatesOfTheMonth] = useState(null);
  const [displayWorklogCount, setDisplayWorklogCount] = useState({});
  const [displayUserCount, setDisplayUserCount] = useState({});
  const [customers, setCustomers] = useState(null);
  const [feedbacks, setFeedbacks] = useState(null);
  const [worklogDateRange, setWorklogDateRange] = useState(null);
  const [userDateRange, setUserDateRange] = useState(null);
  const [selectedKey, setSelectedKey] = useState();
  const [analyticsLoading, setAnalyticsLoading] = useState(false);
  const [openBillModal, setOpenBillModal] = useState(null);
  const [customerData, setCustomerData] = useState(null);
  const [openModal, setOpenModal] = useState(null);

  useEffect(() => {
    const startDay = dayjs().startOf("month");
    const endDay = dayjs().endOf("month");
    let month = [];
    let startOfTheDay = startDay;
    do {
      month.push(startOfTheDay.format("YYYY-MM-DD"));
      startOfTheDay = startOfTheDay.add(1, "day");
    } while (startOfTheDay.valueOf() <= endDay.valueOf());

    setDatesOfTheMonth(month);
  }, []);
  useEffect(() => {
    getCustomers().then((customers) => setCustomers(customers));
    getFeedbacks().then((feedbacks) => setFeedbacks(feedbacks));
  }, []);

  useEffect(() => {
    if (worklogDateRange?.length > 1) {
      const worklogCount = worklogDateRange.reduce((acc, cur) => {
        let worklogDate = dayjs(cur["date"]).format("YYYY-MM-DD");
        if (worklogDate in acc) {
          acc[worklogDate]++;
        } else {
          acc[worklogDate] = 1;
        }
        return acc;
      }, {});
      setDisplayWorklogCount(worklogCount);
    } else {
      setDisplayWorklogCount({});
    }
  }, [worklogDateRange]);

  useEffect(() => {
    if (userDateRange?.length > 1) {
      const userCount = userDateRange.reduce((acc, cur) => {
        const date = dayjs(cur["createdAt"]).format("YYYY-MM-DD");
        if (date in acc) {
          acc[date]++;
        } else {
          acc[date] = 1;
        }
        return acc;
      }, {});
      setDisplayUserCount(userCount);
    } else {
      setDisplayUserCount({});
    }
  }, [userDateRange]);

  useEffect(() => {
    const exportAnalytics = async () => {
      setAnalyticsLoading(true);
      const lastThirtyDays = dayjs().subtract("30", "days").valueOf();
      const currentDay = dayjs().valueOf();

      let worklogs = await getWorklogDateRange(
        selectedKey,
        currentDay,
        lastThirtyDays
      );
      setWorklogDateRange(worklogs);

      let userDateRange = await getUserDateRange(
        selectedKey,
        currentDay,
        lastThirtyDays
      );
      setUserDateRange(userDateRange);
      setAnalyticsLoading(false);
    };

    if (selectedKey) {
      exportAnalytics();
    }
  }, [selectedKey]);

  useEffect(() => {
    getCustomers().then((customers) => setCustomers(customers));
    getFeedbacks((res) => {
      const feedbackFromApi = res.docs.map((doc) => doc.data());
      setFeedbacks(feedbackFromApi);
    });
  }, []);
  function getExpectedAmount(usersCount) {
    const count = usersCount > 10 ? usersCount - 10 : 0;
    const expectedBillAmount = 5000 + count * 500;
    return expectedBillAmount ?? 0;
  }

  function renderCustomers() {
    const openModal = (key) => {
      setSelectedKey(key);
      setIsModalVisible(true);
    };

    const handleCancel = () => {
      setIsModalVisible(false);
    };

    return (
      <>
        <table>
          <thead>
            <tr>
              <th>SN</th>
              <th>Name</th>
              <th>Url</th>
              <th>Administrator</th>
              <th>Onboard Status</th>
              <th>Contact</th>
              <th>Expiry/Due Date</th>
              <th>Users</th>
              <th>Expected Bill Amount</th>
              <th>Customer Stats</th>
              <th>Analytics</th>
            </tr>
          </thead>
          <tbody>
            {customers.map((customer, index) => (
              <tr key={customer.key}>
                <td>{index + 1}</td>
                <td>{customer.customer_name}</td>
                <td>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://sewa360.netlify.app/${customer.subdomain}`}
                  >{`https://sewa360.netlify.app/${customer.subdomain}`}</a>
                </td>
                <td>{customer.admin_name + ": " + customer.email} </td>
                <td>
                  {customer.onBoarded ? (
                    "Onboarding Complete"
                  ) : (
                    <span>
                      Not Onboarded{" "}
                      <ResendOnboardingMailButton
                        email={customer.email}
                        subdomain={customer.subdomain}
                        customerName={customer.customer_name}
                      />
                    </span>
                  )}
                </td>
                <td>{customer.phone}</td>
                <td>
                  {moment(customer.expiryDate).format("YYYY-MM-DD")}
                  <br />
                  {moment(customer.dueDate).format("YYYY-MM-DD")}
                </td>
                <td>{customer.stats?.users ?? 0}</td>
                <td>
                  {getExpectedAmount(customer.stats?.users)}
                  <Button
                    size="small"
                    type="link"
                    icon={<EyeOutlined />}
                    style={{ marginLeft: 10 }}
                    onClick={() => {
                      setCustomerData(customer);
                      setOpenBillModal(true);
                    }}
                  ></Button>
                </td>
                <td>
                  <Button
                    size="small"
                    type="link"
                    onClick={() => {
                      setCustomerData(customer);
                      setOpenModal(true);
                    }}
                  >
                    View
                  </Button>
                </td>
                <td>
                  <Button onClick={() => openModal(customer.key)}>
                    Export
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Modal
          visible={isModalVisible}
          footer={null}
          onCancel={handleCancel}
          bodyStyle={{
            height: 500,
            overflowX: "hidden",
            overflowY: "scroll",
          }}
        >
          {analyticsLoading ? (
            <h1>Loading...</h1>
          ) : (
            <table
              style={{ width: "100%", margin: "auto", overflow: "scroll" }}
            >
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Worklog</th>
                  <th>User</th>
                </tr>
              </thead>
              <tbody>
                {datesOfTheMonth?.map((d) => (
                  <tr style={{ textAlign: "center" }} key={d}>
                    <td>{d}</td>
                    <td>
                      {displayWorklogCount[d] ? displayWorklogCount[d] : 0}
                    </td>
                    <td>{displayUserCount[d] || 0}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </Modal>
      </>
    );
  }

  if (customers === null) return <h2>Loading...</h2>;

  if (customers.length === 0)
    return (
      <div>
        <h2>No Customers, click on 'Add Customer' to create a customer</h2>
        <Link to="/new-customer">Add Customer</Link>
      </div>
    );

  return (
    <div className="page">
      <h1>Customers Page</h1>
      {customerData ? (
        <>
          <MyModal
            openModal={openModal}
            setOpenModal={setOpenModal}
            data={customerData.stats}
          />
          <BillModal
            openModal={openBillModal}
            setOpenModal={setOpenBillModal}
            data={customerData}
          />
        </>
      ) : null}
      <button
        className="logout-button"
        onClick={() => history.push("/new-customer")}
      >
        Add Customer
      </button>
      <div style={{ display: "flex" }}>{renderCustomers()}</div>
      <h1>Feedbacks:</h1>
      <ul>
        {feedbacks &&
          feedbacks.map((f) => {
            return (
              <li key={f.key}>
                <strong>{f.topic} </strong>
                <span>({f.resolved ? "resolved" : "NOT RESOLVED"})</span>
                <p>{f.created_date}</p>
                <p> {f.feedback_message}</p>
                <p>By: {f.username}</p>
                <p>
                  {f.resolved ? null : (
                    <button onClick={() => resolveFeedback(f.key)}>
                      Resolve
                    </button>
                  )}
                </p>
              </li>
            );
          })}
      </ul>
    </div>
  );
}

function MyModal({ openModal, setOpenModal, data }) {
  return (
    <Modal
      title="Customer Stats"
      visible={openModal}
      footer={null}
      onOk={() => {
        setOpenModal(true);
      }}
      onCancel={() => {
        setOpenModal(false);
      }}
    >
      <ul>
        {Object.keys(data).map((stat, i) => {
          return (
            <li key={i}>
              {stat}: {data[stat]}
            </li>
          );
        })}
      </ul>
    </Modal>
  );
}

function BillModal({ openModal, setOpenModal, data }) {
  const [bills, setBills] = useState();
  const [openSentModal, setOpenSentModal] = useState(false);
  const [paying, setPaying] = useState(false);

  const [bill, setBill] = useState();

  const { Option } = Select;

  useEffect(() => {
    getBills(data.key, setBills);
  }, [data]);

  function onFinish(values) {
    const { extendedBy } = values;
    const dayMilliseconds = 24 * 60 * 60 * 1000;
    const date = getExtendedDate();

    function getExtendedDate() {
      switch (extendedBy) {
        case 1:
          return data.dueDate + 1 * dayMilliseconds;
        case 3:
          return data.dueDate + 3 * dayMilliseconds;
        case 7:
          return data.dueDate + 7 * dayMilliseconds;
        default:
          break;
      }
    }

    extendDueDate(data.key, date).then((_) => {
      setOpenModal(false);
      message.success("Due date of customer extended.");
    });
  }
  const [billLoading, setBillLoading] = useState(false);

  return (
    <Modal
      title="Customer Bills"
      visible={openModal}
      width={1000}
      footer={null}
      onOk={() => {
        setOpenModal(true);
      }}
      onCancel={() => {
        setOpenModal(false);
      }}
    >
      {bill ? (
        <SentModal
          openModal={openSentModal}
          setOpenModal={setOpenSentModal}
          data={bill}
          customer={data}
          paying={paying}
          setPaying={setPaying}
        />
      ) : null}

      {!bills ? (
        <h2>loading...</h2>
      ) : (
        <>
          {bills && bills.length === 0 ? (
            <div>
              <Empty
                description="You have no any pending bills."
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            </div>
          ) : null}
          <div>
            {bills && bills.length !== 0 ? (
              <Form
                labelAlign="left"
                name="normal_login"
                className="login-form"
                onFinish={onFinish}
                style={{ display: "flex" }}
              >
                <Form.Item
                  name="extendedBy"
                  label="Extend Due Date By"
                  labelCol={1}
                  colon={false}
                >
                  <Select
                    placeholder="Extend Due Date By"
                    style={{ width: 100, marginTop: 15 }}
                  >
                    <Option key="1" value={1}>
                      1 Day
                    </Option>
                    <Option key="3" value={3}>
                      3 Days
                    </Option>
                    <Option key="7" value={7}>
                      7 Days
                    </Option>
                  </Select>
                </Form.Item>

                <Button
                  type="primary"
                  className="login-form-button"
                  htmlType="submit"
                  style={{ marginTop: "15px", marginLeft: 20 }}
                >
                  Extend
                </Button>
              </Form>
            ) : null}
            {bills?.map((bill) => {
              return (
                <Bill
                  key={bill.key}
                  bill={bill}
                  setBill={setBill}
                  setBillLoading={setBillLoading}
                  billLoading={billLoading}
                  customer={data}
                  setOpenSentModal={setOpenSentModal}
                />
              );
            })}
          </div>
        </>
      )}
    </Modal>
  );
}

function Bill({
  bill,
  setBillLoading,
  setBill,
  billLoading,
  customer: data,
  setOpenSentModal,
}) {
  function download(url) {
    const link = document.createElement("a");
    link.href = url;
    link.download = url.split("/").pop();
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
  function handleChange(val, billKey) {
    const customerKey = data.key;
    setBillLoading(true);
    setStrgBill(val, customerKey, billKey, setBillLoading);
  }
  function handleBillChange(val, billKey) {
    const customerKey = data.key;
    setBillLoading(true);
    uploadVatBill(val, customerKey, billKey, setBillLoading);
  }
  const inputRef = useRef();
  const billInputRef = useRef();
  function handleInput() {
    return inputRef.current.click();
  }

  return (
    <div
      key={bill.key}
      style={{
        marginBottom: "4px",
        padding: "20px",
        border: "1px #F0F2F5 solid",
        width: "60%",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <span
          style={{
            fontWeight: "bold",
            fontSize: "17px",
            display: "block",
          }}
        >
          {bill.title ? (
            bill.title
          ) : (
            <span>
              Bill for {moment(bill.createdAt).format("MMMM, YYYY ") ?? "---"}
            </span>
          )}
        </span>
        <span style={{ fontSize: 14 }}>
          <span style={{ fontWeight: 600 }}> Due date </span>{" "}
          {moment(bill.dueDate).format("MMMM D, YYYY ") ?? "---"}
        </span>
        <span style={{ fontSize: 14 }}>
          <span style={{ fontWeight: 600 }}> Generated date </span>{" "}
          {moment(bill.createdAt).format("MMMM D, YYYY ") ?? "---"}
        </span>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div></div>
        {bill?.paid === false ? (
          <p
            style={{
              margin: 0,
              fontSize: 16,
              fontWeight: "bold",
              marginRight: "10px",
              color: "red",
            }}
          >
            Unpaid
          </p>
        ) : bill?.paid === false ? null : (
          <p
            style={{
              margin: 0,
              fontSize: 16,
              fontWeight: "bold",
              marginRight: "10px",
              color: "green",
            }}
          >
            Paid
          </p>
        )}
      </div>
      <div>
        {bill.lineItems ? (
          <table style={{ width: "100%", marginTop: 20 }}>
            <thead>
              <tr>
                <th>Description</th>
                <th> </th>
              </tr>
            </thead>
            <tbody>
              {bill.lineItems?.map((b, i) => {
                return (
                  <tr key={i}>
                    <td style={{ padding: 8 }}>{b.description ?? "---"}</td>
                    <td>
                      {isNaN(Number(b.amount)) ? (
                        <span style={{ fontWeight: 600 }}>Rs. {b.amount}</span>
                      ) : (
                        <span style={{ fontWeight: 600 }}>
                          {" "}
                          Rs. {+b.amount.toFixed(2)}
                        </span>
                      )}
                    </td>
                  </tr>
                );
              })}
              <tr style={{ fontWeight: 600 }}>
                <td>Amount after TDS</td>
                <td>
                  Rs.{" "}
                  {bill.lineItems.find((i) => i.description === "Total")
                    .amount -
                    (bill.lineItems.find((i) => i.description === "Sub Total")
                      .amount *
                      1.5) /
                      100}
                </td>
              </tr>
            </tbody>
          </table>
        ) : null}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          {bill.imageUrl ? (
            <>
              <Button
                size="small"
                style={{
                  fontSize: "15px",
                  fontWeight: 600,
                  marginTop: 10,
                  height: 40,
                  width: "fit-content",
                }}
                onClick={() => download(bill?.imageUrl)}
              >
                Download Payment Confirmation
              </Button>
            </>
          ) : null}

          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Form.Item name="billing-img" label="Payment Confirmation">
              <input
                type="file"
                onChange={(e) => {
                  handleChange(e.target.files, bill.key);
                }}
                ref={inputRef}
                style={{ display: "none" }}
              />
              <Button
                style={{ marginTop: "15px" }}
                onClick={handleInput}
                loading={billLoading}
              >
                Upload
              </Button>
            </Form.Item>
            {bill.paid ? (
              <Form.Item name="vat-bill" label="VAT Bill Image">
                <input
                  type="file"
                  onChange={(e) => {
                    handleBillChange(e.target.files, bill.key);
                  }}
                  ref={billInputRef}
                  style={{ display: "none" }}
                />
                <Button
                  style={{ marginTop: "15px" }}
                  onClick={() => billInputRef.current.click()}
                  loading={billLoading}
                >
                  Upload
                </Button>
              </Form.Item>
            ) : null}
            {bill.billUrl ? (
              <Button
                size="small"
                style={{
                  fontSize: "15px",
                  fontWeight: 600,
                  marginTop: 10,
                  height: 40,
                  width: 150,
                }}
                onClick={() => download(bill?.billUrl)}
              >
                Download VAT Bill
              </Button>
            ) : null}
          </div>
          {bill?.paid === false ? (
            <Button
              size="small"
              style={{
                fontSize: "15px",
                fontWeight: 600,
                marginTop: 10,
                height: 40,
                width: 150,
              }}
              onClick={() => {
                setBill(bill);
                setOpenSentModal(true);
              }}
            >
              Mark as Paid
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
}

function SentModal({
  openModal,
  setOpenModal,
  data,
  customer,
  setPaying,
  paying,
}) {
  const [form] = Form.useForm();
  function onFinish(values) {
    values.paidByAdminAt = dayjs().valueOf();
    values.merchant_invoiceKey = data.key;
    values.merchant_customerKey = customer.key;

    setPaying(true);
    markAsPaid(values).then((_) => {
      setOpenModal(false);
      setPaying(false);
      form.resetFields();
      message.success("Bill is marked as paid");
    });
  }

  return (
    <Modal
      title="Finalize Bill"
      visible={openModal}
      footer={null}
      onOk={() => {
        setOpenModal(true);
      }}
      onCancel={() => {
        setOpenModal(false);
      }}
    >
      <Form
        form={form}
        labelAlign="left"
        name="normal_login"
        className="login-form"
        onFinish={onFinish}
      >
        <Form.Item
          name="description"
          label="Description"
          autoComplete="off"
          rules={[{ required: true, message: "Please input description!" }]}
        >
          <Input placeholder="Description" />
        </Form.Item>

        <Button
          type="primary"
          className="login-form-button"
          loading={paying}
          htmlType="submit"
          style={{ marginLeft: "auto" }}
        >
          Submit
        </Button>
      </Form>
    </Modal>
  );
}

function ResendOnboardingMailButton({ email, customerName, subdomain }) {
  const [status, setStatus] = useState("idle");
  async function handleClick() {
    setStatus("loading");
    await resendOnboardingMail({
      email,
      customer_name: customerName,
      subdomain,
    });
    setStatus("idle");
  }
  return (
    <div>
      <button disabled={status !== "idle"} onClick={handleClick}>
        {status === "loading" ? "Sending" : "Resend Link"}
      </button>
    </div>
  );
}
