import React from "react";
import { auth } from "../api";
import styles from "./LoginPage.module.css";

export default function LoginPage() {
  let usernameRef = React.useRef();
  let passwordRef = React.useRef();

  function login(e) {
    e.preventDefault();
    auth
      .signInWithEmailAndPassword(
        usernameRef.current.value,
        passwordRef.current.value
      )
      .catch(e => {
        alert(e.message);
      });
  }

  return (
    <div className={styles.page}>
      <form onSubmit={login}>
        <div className="formGroup">
          <label htmlFor="username" className={styles.label}>
            Username/Email:
          </label>
          <input type="text" name="username" ref={usernameRef} />
        </div>
        <div className="formGroup">
          <label htmlFor="password" className={styles.label}>
            Password:
          </label>
          <input
            type="password"
            name="password"
            id="password"
            ref={passwordRef}
          />
        </div>
        <div className="formGroup">
          <input type="submit" value="Log In" />
        </div>
      </form>
    </div>
  );
}
